import React from "react"
import { graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import Layout from '../components/layout'
import ProjectHeader from '../components/projectHeader'
import SEO from '../components/seo'
import { motion } from "framer-motion"

export default function PageTemplate({ data: { mdx } }) {
  const front = mdx.frontmatter
  return (
    <Layout>
      <SEO title={front.title} />
      <ProjectHeader front={front}/>
      <motion.div initial={{ opacity: 0}} animate={{ opacity: 1 }} className="pb-10 px-8 md:px-12">
        <MDXRenderer>{mdx.body}</MDXRenderer>
      </motion.div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query ProjectQuery($id: String) {
    mdx(id: { eq: $id }) {
      id
      body
      frontmatter {
        title
        tags
        excerpt
        link
        featuredImg {
          childImageSharp {
            fluid(maxWidth: 2000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
