import PropTypes from "prop-types";
import React from "react";
import { BlobMask } from '../components/blobs'
import { WavyLink } from '../components/wavyLink'
import { motion } from "framer-motion"

const ProjectHeader = ({ front }) => {
  const listTags = front.tags.split(",");
  const tagsLen = listTags.length;
  return (
    <div className=" flex flex-row text-bleu dark-mode:text-neige">
      <motion.div initial={{ opacity: 0, scale: 1.25 }} animate={{ top: -100, right: -120, scale: 1.25, opacity: 1}} className="absolute right-0 top-0 z-0">
        <BlobMask imgSrc={front.featuredImg.childImageSharp.fluid.src} />
      </motion.div>
      <div className="w-full h-full relative z-10 left-0 top-0 flex flex-col p-8 md:p-12 justify-start items-start text-left">
        <h1 className="">{front.title}</h1>
        <h3 className="">{front.excerpt}</h3>
        <p className="text-sm">
        {listTags &&
          listTags.map((tag, i) => {
            if (tagsLen === i + 1) {
              return <span className="font-sans">{tag}</span>;
            } else {
              return <span className="font-sans">{tag} <span className="font-serif italic">&</span></span>;
            }
          })}
          </p>
        <div className="flex mt-8">
          {front.link && <WavyLink slug={front.link} text="view site" external />
          }
          {front.git && <WavyLink slug={front.git} text="view on GitHub" external />}
        </div>
      </div>
    </div>
  );
};

ProjectHeader.propTypes = {
  front: PropTypes.object
};

ProjectHeader.defaultProps = {
  front: "",
};

export default ProjectHeader;
